<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    class="q-pa-md justify-center"
    position="bottom"
  >
    <q-card
      class="q-ma-md q-pa-md"
      :style="{ 'font-family': settings.typography }"
    >
      <q-card-section class="justify-between row q-pt-md q-pb-xs">
        <div class="text-subtitle1">
          {{ $t("BuyingProcess.products.pickup-store") }}
        </div>
      </q-card-section>

      <q-card-section class="q-ml-md">
        <div v-if="shops.length > 0" class="q-pa-xs">
          <div v-for="shop in shops" class="column">
            <q-radio
              v-model="chosenOption"
              :val="shop"
              :label="formatAddress(shop.address.address, shop.address.postal_code, shop.address.city, shop.address.province, shop.address.country)"
              class="justify-between text-body2"
              left-label
            />
          </div>
        </div>
        <q-item-label caption v-else class="text-black q-ml-md">
          {{ $t("BuyingProcess.products.no-shops") }}
        </q-item-label>
      </q-card-section>

      <div class="flex justify-center q-mt-lg">
        <q-btn
          class="cursor-pointer text-center"
          :label="$t('General.save')"
          v-close-popup
          @click="chooseStore()"
          :style="{ backgroundColor: settings.color_accent }"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import settings from "@/mixins/settings";
import { formatAddress } from "@/services/helpers/AddressFormat";

export default {
  mixins: [settings],
  props: {
    dialog: {
      default: false,
    },
    product: {
      type: Object,
    },
    points: {
      type: Object
    },
  },
  emits: ['passOptionStore', 'storeSelected'],
  setup() {
    return {
      maximizedToggle: true,
      chosenOption: ref(),
    };
  },
  computed: {
    shops() {
      return this.$store.state.shops.shops;
    },
  },
  methods: {
    formatAddress,

    ...mapActions("shops", ["getShops"]),

    // Envía un emit con la opción escogida
    sendStoreAddress() {
      this.$emit("passOptionStore", this.chosenOption);
    },

    chooseStore() {
      this.$emit("passOptionStore", this.chosenOption);
      this.$emit("storeSelected", this.chosenOption);
    }
  },
  mounted() {
    this.getShops();
   },
};
</script>

<style lang="scss" scoped>
.q-card {
  height: 50vh;
}

/*.q-radio {
  display: flex;
  justify-content: space-between;
  font-size: 50px;
}*/

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 560px;
    position: relative;
    // left: 26%;
    min-width: 466px;
    top: 0px;
    min-height: 300px;
    border-radius: 25px 25px 25px 0px !important;
  }
}

.q-dialog__inner--maximized > div {
  border-radius: 25px 25px 25px 0px !important;
}

.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
  background-color: v-bind("settings.color_primary");
}
</style>
